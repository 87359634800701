<template>
    <section id="AircraftIndexSection">
        <vue-headful title="FLPN Aviasolutions - List Aircraft"></vue-headful>
        <h1 class="title">Aircraft</h1>
        <div class="columns">
            <div class="column is-12 is-full-mobile fixedleft">
                <div class="card">
                    <div class="card-content">
                        <form action="#" autocomplete="off" v-on:submit.prevent="paginate">
                            <div class="columns" style="margin-bottom:10px;">
                                <div class="column is-2">
                                    <label class="label">Tail Number</label>
                                    <b-input v-model="filter.tail_number"></b-input>
                                </div>
                                <div class="column is-2">
                                  <PaginationResults v-model="pagination.perPage" />
                                </div>
                                <div class="column is-2">
                                    <label class="label">&nbsp;</label>
                                    <button type="submit" class="button is-link is-fullwidth">
                                        <span class="icon">
                                        <i class="fas fa-filter"></i>
                                        </span>
                                        <span class="has-text-weight-bold is-uppercase">Filter Aircraft</span>
                                    </button>
                                </div>
                                <div class="column is-3">
                                </div>
                                <div class="column is-3 is-fullwidth" v-if="$auth.check(['admin'])">
                                    <label class="label">&nbsp;</label>
                                    <router-link :to="'/aircrafts/create'" class="button is-link is-fullwidth">
                                        <span class="icon">
                                        <i class="fas fa-plus"></i>
                                        </span>
                                        <span class="has-text-weight-bold is-uppercase">Create a new Aircraft</span>
                                    </router-link>
                                </div>
                            </div>
                        </form>
                        <b-table
                            paginated
                            backend-pagination
                            backend-sorting
                            :hoverable="true"
                            :data="pagination.records"
                            :loading="pagination.loading"
                            :total="pagination.total"
                            :per-page="pagination.perPage"
                            :default-sort-direction="pagination.defaultSortOrder"
                            :default-sort="[pagination.sortField, pagination.sortOrder]"
                            :row-class="(row, index) => row.is_selected === true && 'is-info'"
                            @page-change="onPageChange"
                            @sort="onSort">
                            <template slot-scope="props">
                                <b-table-column field="tail_number" label="Tail Number" sortable>
                                    <span>{{ props.row.tail_number }}</span>
                                </b-table-column>
                                <b-table-column field="weight_unit" label="Weight Unit" sortable>
                                    <span>{{ props.row.weight_unit }}</span>
                                </b-table-column>
                                <b-table-column field="fleet" label="Fleet">
                                    <span>{{ props.row.fleet.name}}</span>
                                </b-table-column>
                                <b-table-column field="mtw" label="MTW" sortable>
                                    <span>{{ props.row.mtw}}</span>
                                </b-table-column>
                                <b-table-column field="mtow" label="MTOW" sortable>
                                    <span>{{ props.row.mtow}}</span>
                                </b-table-column>
                                <b-table-column field="mzfw" label="MZFW" sortable>
                                    <span>{{ props.row.mzfw}}</span>
                                </b-table-column>
                                <b-table-column field="mlw" label="MLW" sortable>
                                    <span>{{ props.row.mlw}}</span>
                                </b-table-column>
                                <b-table-column field="mfuel" label="Fuel Capacity" sortable>
                                    <span>{{ props.row.mfuel}}</span>
                                </b-table-column>
                                <b-table-column field="mfuel" label="Max Usable Fuel" sortable>
                                    <span>{{ props.row.max_usable_fuel}}</span>
                                </b-table-column>
                                <b-table-column type="number" step="0.1" min="0" max="100" field="deg" label="Fuel Degradation" sortable>
                                    <span>{{ props.row.deg}}</span>
                                </b-table-column>
                                <b-table-column field="mmid" label="MM ID" sortable>
                                    <span>{{ props.row.mmid}}</span>
                                </b-table-column>
                                <b-table-column field="default_climb_speed" label="Default Climb Speed" sortable>
                                    <span>{{ props.row.default_climb_speed}}</span>
                                </b-table-column>
                                <b-table-column field="default_cruise_speed" label="Default Cruise Speed" sortable>
                                    <span>{{ props.row.default_cruise_speed}}</span>
                                </b-table-column>
                                <b-table-column field="default_descent_speed" label="Default Descent Speed" sortable>
                                    <span>{{ props.row.default_descent_speed}}</span>
                                </b-table-column>
                                <b-table-column field="client" label="Client">
                                    <span>{{ props.row.client.name}}</span>
                                </b-table-column>
                                <b-table-column width="80" v-if="$auth.check(['admin-client'])">
                                    <div class="field has-addons">
                                        <p class="control">
                                            <router-link :to="`/aircrafts/${props.row.id}/edit`" class="button is-small is-link is-success">
                                                EDIT
                                            </router-link>
                                        </p>
                                    </div>
                                </b-table-column>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>
<script>
import AircraftService from "../../services/aircrafts.service.js";
import AircraftsPaginationFilter from "../../filters/aircrafts-pagination.filter";
import PaginationResults from "@/components/shared/PaginationResults";
export default {
    name: "AircraftIndex",
  components: {PaginationResults},
  data() {
        return {
            isLoading: false,
            aircraftsService: new AircraftService(),
            pagination: {
                records: [],
                total: 0,
                loading: false,
                sortField: "id",
                sortOrder: "desc",
                defaultSortOrder: "desc",
                page: 1,
                perPage: 20
            },
            filter: new AircraftsPaginationFilter()
        };
    },
    methods: {
        paginate() {
            const params = {
                sort_field: this.pagination.sortField,
                sort_order: this.pagination.sortOrder,
                per_page: this.pagination.perPage,
                page: this.pagination.page
            };
            if (!this.filter.isEmpty()) {
                params.filter = this.generateFilters();
            }
            this.pagination.loading = true;
            this.aircraftsService.paginate(params).then(
                ({data}) => {
                    this.pagination.records = data.data;                    
                    this.pagination.total = data.meta.total;                    
                    this.pagination.loading = false
                },
                () => {
                    this.pagination.records = []
                    this.pagination.total = 0
                    this.pagination.loading = false
                    this.$buefy.toast.open({
                        message: 'An error ocurred while paginating!!!',
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                }
            );
        },
        generateFilters() {
            var filtro = {};
            if (this.filter.hasValue(this.filter.tail_number)) {
                filtro.tail_number = this.filter.tail_number;
            }
            return filtro;
        },
        /*
        * Handle page-change event
        */
        onPageChange(page) {
            this.pagination.page = page;
            this.paginate();
        },
        /*
        * Handle sort event
        */
        onSort(field, order) {
            this.pagination.sortField = field;
            this.pagination.sortOrder = order;
            this.paginate();
        }
    },
    mounted() {
        this.paginate();
    },
};
</script>
<style>
    tr.is-info {
        background: #167df0;
        color: #fff;
    }
    #AircraftIndexSection .card-header {
      background-color: lightgrey;
    }
</style>